@import "../styles/mixin.scss";

.benefitCard {
  cursor: pointer;
  @include Flex();
  flex-direction: column;
  width: 500px;

  .imgContainer {
    @include Flex();
    height: 300px;
    width: 300px;
    border-radius: 50%;
    background-color: #d9d9d9;
    overflow: hidden;
    img {
      width: 100%;
    }
  }

  .contentData {
    text-align: center;
    @include Flex();
    flex-direction: column;
    gap: 0.7rem;
    margin-top: 1rem;

    h3 {
      margin: 0;
      padding: 0;
      font-size: 1.5rem;
      font-weight: 700;
    }
    p {
      margin: 0;
      font-weight: 400;
      font-size: rem;
    }
  }
  @media (max-width: 1125px) {
    width: 300px;
    .imgContainer {
      width: 250px;
      height: 250px;
    }
    .contentData {
      gap: 0.5rem;
      h2 {
        font-size: 1.2rem;
      }
      p {
        font-size: 1rem;
        width: 80%;
        text-align: center;
      }
    }
  }
  @media (max-width: 874px) {
    width: 250px;
    .imgContainer {
      width: 230px;
      height: 230px;
    }
    .contentData {
      gap: 0.5rem;
      h3 {
        font-size: 1.1rem;
      }
      p {
        font-size: 0.9rem;
        width: 80%;
        text-align: center;
      }
    }
  }
  @media (max-width: 750px) {
    width: 200px;
    .imgContainer {
      width: 200px;
      height: 200px;
    }
    .contentData {
      gap: 0.5rem;
      h3 {
        font-size: 1.1rem;
      }
      p {
        font-size: 0.9rem;
        width: 80%;
        text-align: center;
      }
    }
  }
  @media (max-width: 700px) {
    width: 350px;
    .imgContainer {
      width: 300px;
      height: 300px;
    }
    .contentData {
      gap: 0.5rem;
      h3 {
        font-size: 1.3rem;
      }
      p {
        font-size: 1.1rem;
        width: 80%;
        text-align: center;
      }
    }
  }
}

// .container {
//   z-index: 10;

//   .upperElipse {
//     position: relative;
//     height: 180px;
//     width: 200px;
//     background-color: #d7e8d5;
//     border-radius: 100px 100px 0 0;
//     @include Flex();
//     z-index: 10;
//     // margin-top: 2rem;
//     overflow: hidden;
//   }

//   .lowerElipse {
//     @include Flex();
//     color: white;
//     height: 180px;
//     width: 200px;
//     background-color: #158d7b;
//     border-radius: 0 0 100px 100px;
//     z-index: -10;
//     transition: 0.5s ease;

//     .benefitContent {
//       transition: 0.5s ease;
//       transition-delay: 0.2s;
//     }
//   }
// }
