@import "../styles/mixin.scss";

.header {
  display: flex;
  justify-content: space-between;
  padding: 1rem 3rem;

  .leftLogo {
    text-decoration: none;
    color: #000000;
    @include Flex();
    img {
      width: 170px;
    }
  }

  .navContainer {
    @include Flex();
    list-style: none;
    font-size: 1.3rem;
    gap: 4rem;

    .navItem {
      font-weight: 700;
      cursor: pointer;

      &:hover {
        color: rgb(120, 120, 120);
        transition: 0.1s ease-in;
      }
    }
  }
  .headerBtns {
    @include Flex();
    gap: 3rem;

    .downloadBtn {
      @include Flex();
      border-radius: 30px;
      border: 2px solid black;

      .androidBtn {
        padding: 0.5rem 2rem;
        border-radius: 30px 0 0 30px;
        border-right: 2px solid black;
        img {
          width: 24px;
        }
      }
      .iosBtn {
        padding: 0.5rem 2rem;
        border-radius: 0 30px 30px 0;
        img {
          width: 22px;
        }
      }
    }

    a {
      color: black;
      text-decoration: none;
      font-size: 1.2rem;
      @include Flex();
      img {
        transition: 0.1s ease-in-out;
        &:hover {
          transform: rotate(10deg);
          transition: 0.1s ease-in-out;
        }
      }
      &:hover {
        background-color: rgb(237, 237, 237);
      }
    }

    .callBtn {
      height: 65px;
      width: 65px;
      transition: 0.1s ease-in-out;
      @include Flex();
      &:hover {
        transform: rotate(10deg);
        transition: 0.1s ease-in-out;
      }
    }
  }
  @media (max-width: 1024px) {
    padding: 1rem;
    .leftLogo {
      text-decoration: none;
      color: #000000;
      img {
        width: 150px;
      }
    }
    .navContainer {
      font-size: 1.2rem;
      gap: 3rem;
    }
    .headerBtns {
      @include Flex();
      gap: 0rem;

      .downloadBtn {
        .androidBtn {
          padding: 0.5rem 1.2rem;
          img {
            width: 22px;
          }
        }
        .iosBtn {
          padding: 0.5rem 1.2rem;
          img {
            width: 24px;
          }
        }
      }
    }
    .callBtn {
      @include Flex();
      height: 20px;
      width: 20px;
      img {
        height: 35px;
        width: 35px;
      }
    }
  }
  @media (max-width: 900px) {
    padding: 1rem;
    .leftLogo {
      img {
        width: 120px;
      }
    }
    .navContainer {
      font-size: 1.2rem;
      gap: 2rem;
    }
    .headerBtns {
      @include Flex();
      gap: 1rem;
    }
    .callBtn {
      @include Flex();
      height: 20px;
      width: 20px;
      img {
        height: 40px;
        width: 40px;
      }
    }
  }
  @media (max-width: 751px) {
    padding: 0.5rem 0;
    flex-direction: column;
    .navOuterContainer {
      position: absolute;
      width: 100vw;
      background: transparent;
      top: 80px;
      overflow: hidden;
      z-index: 0;
      img {
        width: 35px;
        // transform: translateY(10px);
        position: absolute;
        right: 20px;
        z-index: 1;
      }
      .navContainer {
        padding: 0 0 5px 10px;
        width: 100vw;
        display: flex;
        background: white;
        justify-content: flex-start;
        // transform: translateY(-120px);
        transition: ease-in-out 0.8s;
        .linkContainer {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 8px;
          font-size: 1.1rem;
        }
      }
    }

    .outerContainer {
      display: flex;
      justify-content: space-between;
      // width: 100vw;
      padding: 0.5rem;

      .hamburgur {
        width: 35px;
        // top: 80px;
        // position: absolute;
        // right: 20px;
      }
      .leftLogo {
        img {
          width: 120px;
        }
      }
      .headerBtns {
        @include Flex();
        gap: 1rem;

        .downloadBtn {
          .androidBtn {
            padding: 0.2rem 1.1rem;
            img {
              width: 18px;
            }
          }
          .iosBtn {
            padding: 0.5rem 1.1rem;
            img {
              width: 18px;
            }
          }
        }
      }
      .callBtn {
        @include Flex();
        flex-direction: column;
        height: 30px;
        width: 30px;
        img {
          height: 30px;
          width: 30px;
        }
      }
    }
  }
}
