@import "../styles/mixin.scss";

.outerContainer {
  width: 100vw;
  .container {
    .howItWorksLabel {
      position: absolute;
      top: 3%;
      left: 2.77%;
      z-index: 1;
      font-size: 300%;
      font-weight: 700;
      // @media (max-width: 1520px) {
      //   font-size: 2rem;
      //   // display: none;
      //   left: 110px;
      // }
      @media (max-width: 1200px) {
        left: 110px;
      }
      @media (max-width: 1024px) {
        left: 110px;
      }
      @media (max-width: 986px) {
        font-size: 250%;
        left: 1.7%;
      }
      @media (max-width: 951px) {
        left: 1.4%;
      }
      @media (max-width: 890px) {
        left: 35px;
      }
      @media (max-width: 855px) {
        left: 20px;
      }
      @media (max-width: 830px) {
        left: 10px;
      }
      @media (max-width: 830px) {
        left: 2px;
      }
      @media (max-width: 800px) {
        left: 0px;
      }
      @media (max-width: 780px) {
        left: 50px;
      }
      @media (max-width: 740px) {
        left: 20px;
      }
      @media (max-width: 700px) {
        display: none;
      }
    }
  }
}

.container {
  width: 400%;
  height: 100vh;
  display: flex;
  // flex-wrap: nowrap;

  .panel {
    width: 100%;
    height: 100%;
    @include Flex();
    gap: 0;

    .leftSection {
      width: 550px;
      height: 100%;
      @include Flex();
      flex-direction: column;
      align-items: flex-end;
      //   padding: 0rem 2rem;
      text-align: left;

      .contentContainer {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        width: 80%;
        .count {
          font-size: 2.5rem;
          font-weight: 700;
          margin: 0;
          margin: 0rem 0rem 1rem 0;
        }
        .heading {
          line-height: 150%;
          font-size: 2.5rem;
          margin: 0;
          //   margin: 1rem 1rem;
        }
        .info {
          // width: 430px;
          margin: 0;
          line-height: 180%;
          font-size: 1.5rem;
          font-weight: 400;
          margin: 0.5rem 0;
        }
      }
    }
    .rightSection {
      width: 55%;
      height: 100%;
      @include Flex();
      align-items: center;

      .imgContainer {
        @include Flex();
        height: 550px;
        width: 550px;
        background-color: white;
        border-radius: 50%;
        overflow: hidden;
      }
    }
  }

  @media (max-width: 1105px) {
    .panel {
      .rightSection {
        // width: 400px;
        .imgContainer {
          height: 450px;
          width: 450px;
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .panel {
      .leftSection {
        width: 400px;
        align-items: start;
        .contentContainer {
          gap: 2rem;
          .count {
            font-size: 1.7rem;
            font-weight: 700;
            margin: 0;
            // margin: 0rem 0rem 3rem 0;
          }
          .heading {
            line-height: 150%;
            font-size: 2rem;
            margin: 0;
            //   margin: 1rem 1rem;
          }
          .info {
            height: 20%;
            line-height: 180%;
            font-size: 1.4rem;
            font-weight: 400;
            margin: 1rem 0 0 0;
          }
        }
      }
      .rightSection {
        width: 400px;
        .imgContainer {
          height: 400px;
          width: 400px;
        }
      }
    }
  }
  @media (max-width: 810px) {
    .panel {
      .rightSection {
        // width: 400px;
        .imgContainer {
          height: 350px;
          width: 350px;
        }
      }
    }
  }
  @media (max-width: 780px) {
    .panel {
      .leftSection {
        width: 330px;
        .contentContainer {
          .count {
            font-size: 1.5rem;
          }
          .heading {
            font-size: 1.8rem;
          }
          .info {
            font-size: 1.2rem;
          }
        }
      }
      .rightSection {
        width: 350px;
        .imgContainer {
          height: 350px;
          width: 350px;
        }
      }
    }
  }
  @media (max-width: 700px) {
    width: 100vw;
    height: 100%;
    flex-direction: column;
    .panel {
      flex-direction: column-reverse;
      padding: 1rem 0;
      gap: 0rem;
      .leftSection {
        width: 100vw;
        justify-content: flex-start;
        align-items: center;
        .contentContainer {
          align-items: center;
          width: 80%;
          padding: 0;
          margin: 0;
          gap: 0rem;
          text-align: center;
          .count {
            display: none;
            font-size: 1.5rem;
          }
          .heading {
            font-size: 1.5rem;
            margin: 0;
          }
          .info {
            margin: 0;
            font-size: 1.1rem;
          }
        }
      }
      .rightSection {
        width: 100vw;
        height: 50vh;
        .imgContainer {
          height: 300px;
          width: 300px;
        }
      }
    }
  }
  @media (max-width: 500px) {
    .panel {
      padding: 1.5rem 0;
      height: 100%;
      .leftSection {
        height: 100%;
        .contentContainer {
          width: 90%;
        }
      }
    }
  }
  #section1 {
    width: 100%;
    height: 100%;
    background-color: #d2dbfe;
  }
  #section2 {
    width: 100%;
    height: 100%;
    background-color: #ffd6a1;
  }
  #section3 {
    width: 100%;
    height: 100%;
    background-color: #cce5ec;
  }
  #section4 {
    width: 100%;
    height: 100%;
    background-color: #ffc1a6;
  }
}
