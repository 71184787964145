@import "../styles/mixin.scss";

.slider_container {
  .sliderArrow {
    height: 40px;
  }
  .forwardBtn {
    position: absolute;
    border: none;
    background: transparent;
    align-self: center;
    right: 6%;
    cursor: pointer;
  }
  .backwardBtn {
    position: absolute;
    border: none;
    background: transparent;
    align-self: center;
    left: 6%;
    z-index: 1;
    cursor: pointer;
  }
  .paginationBtn {
    transform: translateY(-100px);
    height: 20;
    width: 20;
    border-radius: 50%;
    border: 0;
  }
  .activePagination {
    height: 16;
    width: 16;
    border-radius: 50%;
    border: 0;
    background: black;
  }
  .feedbackContainer {
    width: 100vw;
    padding: 1rem 0;
    @include Flex();
    .feedbackCardContiner {
      height: 350px;
      width: 500px;
      background-color: white;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 3rem;
      margin: 4rem 0;
      .comma {
        position: absolute;
        height: 80px;
        transform: translate(-400px, -50px);
      }
      .bgElement {
        position: absolute;
        transform: translate(355px, -94px);
        height: 200px;
      }
      .content {
        .contentHeading {
          @include Flex();
          transform: translateY(-40px);
          img {
            height: 85px;
          }
          p {
            position: absolute;
            font-style: italic;
            font-size: 1.8rem;
          }
        }
        .contentInfo {
          @include Flex();
          width: 100%;
          flex-direction: column;
          gap: 1rem;
          p {
            margin: 0;
            font-size: 1.2rem;
            line-height: 2rem;
          }
          .rating {
            width: 100%;
            height: 40px;
            @include Flex();
            justify-content: space-between;
            .ratingLeft {
              width: 50%;
            }
            .ratingRight {
              .profileContainer {
                width: 50%;
                position: absolute;
                height: 150px;
                width: 180px;
                border-radius: 50%;
                transform: translate(-40px, -30px);
                background: #666666;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 1025px) {
    .feedbackContainer {
      // padding: 1rem 0;
      .feedbackCardContiner {
        height: 320px;
        width: 420px;
        padding: 0 2.5rem;
        .comma {
          height: 60px;
          transform: translate(-330px, -50px);
        }
        .bgElement {
          position: absolute;
          transform: translate(290px, -66px);
          height: 140px;
        }
        .content {
          .contentInfo {
            p {
              font-size: 1.1rem;
            }
            .rating {
              .ratingRight {
                .profileContainer {
                  height: 130px;
                  width: 150px;
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .feedbackContainer {
      // padding: 1rem 0;
      .feedbackCardContiner {
        height: 300px;
        width: 380px;
        padding: 0 2.5rem;
        .comma {
          height: 50px;
          transform: translate(-300px, -50px);
        }
        .bgElement {
          position: absolute;
          transform: translate(264px, -57px);
          height: 120px;
        }
        .content {
          .contentHeading {
            img {
              height: 75px;
            }
            p {
              font-size: 1.5rem;
            }
          }
          .contentInfo {
            transform: translateY(-25px);
            gap: 0;
            p {
              font-size: 1.05rem;
            }
          }
        }
      }
    }
  }
  @media (max-width: 671px) {
    .sliderArrow {
      height: 30px;
    }
    .forwardBtn {
      right: 1%;
    }
    .backwardBtn {
      left: 1%;
    }
    .feedbackContainer {
      // padding: 1rem 0;
      .feedbackCardContiner {
        margin: 3rem 0;
        height: 300px;
        width: 350px;
        padding: 0 2rem;
        .comma {
          display: none;
        }
        .bgElement {
          display: none;
        }
        .content {
          .contentInfo {
            transform: translateY(-25px);
            gap: 0;
            p {
              font-size: 1.05rem;
            }
            .rating {
              .ratingRight {
                .profileContainer {
                  height: 100px;
                  width: 120px;
                  transform: translate(-60px, -30px);
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 530px) {
    .forwardBtn {
      right: 1%;
    }
    .backwardBtn {
      left: 1%;
    }
    .feedbackContainer {
      .feedbackCardContiner {
        height: auto;
        width: 270px;
        padding: 0 3rem;
        margin: 4rem 0 1rem 0;

        .content {
          .contentInfo {
            transform: translateY(-25px);
            gap: 0;
            p {
              font-size: 1rem;
              text-align: justify;
            }
            .rating {
              .ratingRight {
                .profileContainer {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 430px) {
    .sliderArrow {
      height: 25px;
    }
    .forwardBtn {
      right: 0%;
    }
    .backwardBtn {
      left: 0%;
    }
    .feedbackContainer {
      .feedbackCardContiner {
        margin: 1rem 0;
        height: auto;
        width: 330px;
        padding: 0;

        .content {
          .contentHeading {
            transform: translateY(-30px);
            img {
              height: 60px;
            }
            p {
              font-size: 1.3rem;
            }
          }
          .contentInfo {
            width: 90%;
            margin: auto;
            transform: translateY(-25px);
            gap: 0;
            p {
              width: 100%;
              font-size: 0.9rem;
              line-height: 170%;
              text-align: justify;
            }
            .rating {
              .ratingRight {
                .profileContainer {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
