@import "../styles/mixin.scss";

.hero {
  @include Flex();
  height: 100vh;
  z-index: 100;
  flex-direction: column;
  h2 {
    font-size: 5rem;
    text-align: center;
    margin: 0;
  }
  p {
    font-size: 2rem;
    text-align: center;
    line-height: 3rem;
    opacity: 0;
    // margin: 10px 0 15px 0;
  }

  a {
    width: 200px;
    height: 60px;
    text-decoration: none;
    color: black;
    font-weight: 900;
    // font-style: "bold";
  }

  @media (max-width: 1250px) {
    h2 {
      font-size: 4rem;
    }
  }
  @media (max-width: 1024px) {
    h2 {
      font-size: 3.5rem;
    }
    p {
      font-size: 2rem;
      line-height: 3.5rem;
    }
    a {
      width: 170px;
      height: 55px;
    }
  }
  @media (max-width: 768px) {
    h2 {
      font-size: 2.5rem;
    }
    p {
      font-size: 2rem;
      line-height: 3rem;
    }
    a {
      width: 170px;
      height: 55px;
    }
  }

  @media (max-width: 426px) {
    // padding: 0 0.rem;
    h2 {
      font-size: 2rem;
    }
    p {
      line-height: 2rem;
      font-size: 1.5rem;
    }
    a {
      width: 150px;
      height: 50px;
    }
  }
}
.videoContainer {
  position: absolute;
  background-color: blue;
  overflow: hidden;
  width: 100vw;
  height: 100%;
  z-index: 0;
  transform: translate(0, -10px);
}

.char {
  transform: translateY(100px);
  transition: transform 0.5s;
}

.slider {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #007b7b;
  width: 100vw;
  height: 120vh;
  transform: translateY(-100%);
}
