@import "../styles/mixin.scss";

.slider_container {
  .sliderArrow {
    height: 50px;
  }
  .forwardBtn {
    position: absolute;
    border: none;
    background: transparent;
    align-self: center;
    right: 7%;
    cursor: pointer;
  }
  .backwardBtn {
    position: absolute;
    border: none;
    background: transparent;
    align-self: center;
    left: 7%;
    z-index: 1;
    cursor: pointer;
  }
  .paginationBtn {
    transform: translateY(-100px);
    height: 20;
    width: 20;
    border-radius: 50%;
    border: 0;
  }
  .activePagination {
    height: 16;
    width: 16;
    border-radius: 50%;
    border: 0;
    background: black;
  }
  .videoBoxContainer {
    width: 870px;
    .videoBox {
      width: 870px;
      height: 480px;
      margin: 3rem auto;
      margin-bottom: 1rem;
      border-radius: 15px;
      background-color: #b5b5b5;
      overflow: hidden;
    }
    .contentInfo {
      h2 {
        font-weight: 700;
        margin: 0;
        font-size: 2rem;
      }
      p {
        margin: 0;
        margin-top: 10px;
        color: #666666;
      }
    }
  }
  @media (max-width: 1160px) {
    .forwardBtn {
      right: 2%;
    }
    .backwardBtn {
      left: 2%;
    }
  }
  @media (max-width: 1025px) {
    .forwardBtn {
      right: 5%;
    }
    .backwardBtn {
      left: 5%;
    }
    .videoBoxContainer {
      width: 670px;
      .videoBox {
        width: 670px;
        height: 400px;
      }
    }
  }
  @media (max-width: 860px) {
    .forwardBtn {
      right: 1%;
    }
    .backwardBtn {
      left: 1%;
    }
  }
  @media (max-width: 770px) {
    .sliderArrow {
      height: 40px;
    }
    .forwardBtn {
      right: 5%;
    }
    .backwardBtn {
      left: 5%;
    }
    .videoBoxContainer {
      width: 500px;
      .videoBox {
        width: 500px;
        height: 282px;
      }
    }
  }
  @media (max-width: 650px) {
    .forwardBtn {
      right: 1%;
    }
    .backwardBtn {
      left: 1%;
    }
    .videoBoxContainer {
      .contentInfo {
        h2 {
          font-size: 1.8rem;
        }
        p {
          margin-top: 3px;
        }
      }
    }
  }
  @media (max-width: 600px) {
    .sliderArrow {
      height: 30px;
    }
    .forwardBtn {
      right: 1%;
    }
    .backwardBtn {
      left: 1%;
    }
    .videoBoxContainer {
      width: 400px;
      .videoBox {
        width: 400px;
        height: 282px;
        border-radius: 10px;
      }
    }
  }
  @media (max-width: 476px) {
    .forwardBtn {
      right: 0%;
    }
    .backwardBtn {
      left: 0%;
    }
    .videoBoxContainer {
      width: 320px;
      .videoBox {
        width: 320px;
        height: 200px;
        border-radius: 10px;
        margin-bottom: 10px;
      }
      .contentInfo {
        h2 {
          font-size: 1.5rem;
        }
        p {
          font-size: 0.9rem;
        }
      }
    }
  }
}
