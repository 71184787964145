@import "../styles/mixin.scss";
.card {
  width: 400px;
  // margin-right: 3rem;
  .imgContainer {
    height: 300px;
    overflow: hidden;
    @include Flex();
    background-color: #d9d9d9;
    border-radius: 10px;
    img {
      height: 100%;
    }
  }
  .tag {
    color: #2cae8f;
    font-weight: 600;
    font-size: 1rem;
    margin: 20px 0 0 0;
  }
  .title {
    font-size: 1.3rem;
    line-height: 150%;
    margin: 0;
    font-weight: 700;
  }
  .date {
    margin: 5px 0 0 0;
    color: #878181;
    font-size: 1.1rem;
    font-weight: 400;
  }

  @media (max-width: 1440px) {
    width: 300px;
    .imgContainer {
      height: 225px;
    }
    .tag {
      font-size: 0.9rem;
      margin: 10px 0 0 0;
    }
    .title {
      font-size: 1rem;
    }
    .date {
      font-size: 0.8rem;
      margin: 0;
    }
  }
  @media (max-width: 768px) {
    .imgContainer {
      height: 170px;
    }
    .tag {
      font-size: 0.9rem;
      margin: 10px 0 0 0;
    }
    .title {
      font-size: 1rem;
    }
    .date {
      font-size: 0.8rem;
      margin: 0;
    }
  }
  @media (max-width: 600px) {
    width: 390px;
    .imgContainer {
      height: 225px;
    }
    .tag {
      font-size: 0.7rem;
      margin: 10px 0 0 0;
    }
  }
  @media (max-width: 500px) {
    width: 300px;
    .imgContainer {
      height: 225px;
    }
    .tag {
      font-size: 0.7rem;
      margin: 10px 0 0 0;
    }
  }
}
