@import "../styles/mixin.scss";

.slider_container {
  margin: 6rem 0;
  .sliderArrow {
    height: 40px;
  }
  .forwardBtn {
    position: absolute;
    border: none;
    background: transparent;
    align-self: center;
    right: 6%;
    cursor: pointer;
  }
  .backwardBtn {
    position: absolute;
    border: none;
    background: transparent;
    align-self: center;
    left: 6%;
    z-index: 1;
    cursor: pointer;
  }
  .paginationBtn {
    transform: translateY(-100px);
    height: 20;
    width: 20;
    border-radius: 50%;
    border: 0;
  }
  .activePagination {
    height: 16;
    width: 16;
    border-radius: 50%;
    border: 0;
    background: black;
  }
  .postContainer {
    height: 500px;
    width: 1000px;
    @include Flex();
    img {
      height: 100%;
    }
  }
  @media (max-width: 1440px) {
    @include Flex();
    .postContainer {
      height: 400px;
      width: 500px;
      overflow: hidden;
    }
  }
  @media (max-width: 770px) {
    @include Flex();
    .postContainer {
      height: 300px;
      width: 400px;
      overflow: hidden;
    }
  }
  @media (max-width: 600px) {
    .sliderArrow {
      height: 30px;
    }
    .forwardBtn {
      right: 1%;
    }
    .backwardBtn {
      left: 1%;
    }
    @include Flex();
    .postContainer {
      height: 300px;
      width: 400px;
      overflow: hidden;
    }
  }
  @media (max-width: 460px) {
    .sliderArrow {
      height: 25px;
    }
    @include Flex();
    .postContainer {
      height: 200px;
      width: 300px;
      overflow: hidden;
    }
  }
}
