@import "../styles/mixin.scss";

.partners {
  background-color: #e5effb;
  // height: 55vh;
  padding: 3rem 0 5rem 0;
  @include Flex();
  flex-direction: column;
  gap: 2rem;
  h1 {
    // padding: 5rem 0 3rem 0;
    margin: 0rem;
    text-align: center;
    font-size: 3rem;
    color: $dark-blue;
  }

  .cardContainer {
    @include Flex();
    gap: 2rem;
    color: #059176;

    .card {
      @include Flex();
      flex-direction: column;
      gap: 0rem;
      width: 200px;
      height: 200px;
      font-size: 1.5rem;

      .logo {
        width: 100%;
        height: auto;
        @include Flex();
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
  @media (max-width: 950px) {
    .cardContainer {
      gap: 1.5rem;
      .card {
        width: 180px;
        height: auto;
      }
    }
  }
  @media (max-width: 850px) {
    gap: 1rem;
    h1 {
      font-size: 2rem;
    }
    .cardContainer {
      gap: 1rem;
      .card {
        width: 160px;
        height: auto;
      }
    }
  }
  @media (max-width: 710px) {
    gap: 1rem;
    .cardContainer {
      gap: 0.5rem;
      .card {
        width: 150px;
        height: auto;
      }
    }
  }
  @media (max-width: 650px) {
    gap: 0;
    h1 {
      font-size: 2rem;
    }
    .cardContainer {
      flex-direction: column;
      gap: 0;
      .card {
        width: 200px;
        height: 200px;
        gap: 0rem;
        // .logo {
        //   width: 80px;
        //   height: 80px;
        // }

        p {
          font-size: 1.2rem;
        }
      }
    }
  }
}
