@import "../styles/mixin.scss";

.postContent {
    max-width: 900px;
    justify-content: center;
    text-align: justify;

    h2 {
        font-weight: 600;
        font-size: 2rem;
    }

    color: black;

    .qouteContent {
        background-color: #fffdf6;
        padding: 2.5rem 0;
        border-radius: 30px;
        margin: 2rem 0;
        font-size: 2rem;
        width: 100%;
        text-align: center;
        color: #3f3045;
        @include Flex();
        flex-direction: column;

        .qouteContainer {
            display: flex;
            height: 100%;
            justify-content: center;

            // align-items: center;
            .content {
                @include Flex();
                font-size: 1.5rem;
                width: 75%;
                margin: 0;
                line-height: 160%;
            }

            .comma {
                height: auto;

                img {
                    height: 20px;
                }
            }

            #upperComma {
                // transform: translateY(-100%);
                display: flex;
            }

            #lowerComma {
                display: flex;
                align-items: flex-end;
                // transform: translateY(100%);
            }
        }

        .writer {
            font-size: 1.2rem;
            width: 40%;
            text-align: right;
            margin-top: 1rem;
        }
    }

    .imgContainer {
        height: 600px;
        @include Flex();

        img {
            height: 100%;
        }
    }

    @media (max-width: 1025px) {
        .qouteContent {
            .qouteContainer {
                .content {
                    font-size: 1.2rem;
                }
            }

            .writer {
                font-size: 1rem;
            }
        }
    }

    @media (max-width: 770px) {
        .imgContainer {
            height: 400px;
            @include Flex();

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    @media (max-width: 650px) {
        .imgContainer {
            height: 350px;
        }

        .qouteContent {
            padding: 1.5rem 0;

            .qouteContainer {
                .content {
                    width: 80%;
                    font-size: 1.1rem;
                }

                .comma {
                    img {
                        height: 17px;
                    }
                }
            }

            .writer {
                font-size: 0.95rem;
                width: 100%;
                text-align: center;
            }
        }
    }

    @media (max-width: 500px) {
        .imgContainer {
            height: 280px;
        }

        .qouteContent {
            padding: 1.5rem 0;

            .qouteContainer {
                gap: 3px;

                .content {
                    width: 80%;
                    font-size: 1.1rem;
                }

                .comma {
                    img {
                        display: none;
                    }
                }
            }

            .writer {
                font-size: 0.95rem;
                width: 100%;
                text-align: center;
            }
        }
    }

    @media (max-width: 770px) {
        h2 {
            font-size: 1.5rem;
            margin-bottom: 1rem;
        }
    }
}