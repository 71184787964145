@import "../styles/mixin.scss";

.outerContainer {
  width: 100vw;

  .container {
    width: 300%;
    height: 100vh;
    display: flex;
    flex-wrap: nowrap;

    .panel {
      width: 100vw;
      height: 100vh;
      @include Flex();

      .leftSection {
        width: 55%;
        height: 100%;
        @include Flex();
        img {
          height: 85%;
        }
        #slide2Img {
          width: 70%;
          height: auto;
          // height: 60%;
        }
        #slide3Img {
          width: 70%;
          height: auto;
          // height: 60%;
        }
      }
      .rightSection {
        width: 45%;
        height: 100%;
        @include Flex();
        flex-direction: column;
        align-items: flex-start;
        gap: 3rem;
        padding: 0rem 2rem;
        text-align: left;

        .count {
          margin: 0 0 0 2rem;
          font-size: 2rem;
          font-weight: 700;
        }
        .heading {
          margin: 0 0 1rem 2rem;
          line-height: 150%;
          font-size: 2.5rem;
        }
        .info {
          margin: 0 0 0 2rem;
          // width: 430px;
          line-height: 180%;
          font-size: 1.3rem;
          font-weight: 400;
        }
        .howItWorksbtn {
          @include Flex();
          margin: 0 0 0 2rem;
          font-size: 1.3rem;
          text-decoration: underline;
          font-weight: 600;
          cursor: pointer;
          &:hover {
            transition: ease-in 0.1s;
            font-weight: 600;
          }
        }
        .scrollbtn {
          display: inline;
          width: 100px;
          font-weight: 700;
          height: 2rem;
          @include Flex();
          justify-content: flex-start;
          gap: 0.7rem;
          margin: 0 0 0 2rem;
          font-size: 1.2rem;
          cursor: pointer;
          img {
            transition: ease-out 0.2s;
          }
          &:hover {
            img {
              transform: translateX(10px);
              transition: ease-in 0.2s;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1165px) {
    .container {
      .panel {
        .leftSection {
          img {
            height: 70%;
          }
          #slide2Img {
            width: 80%;
            height: auto;
          }
          #slide3Img {
            // width: 90%;
            height: auto;
            // height: 60%;
          }
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .container {
      width: 300%;
      height: 100vh;
      .panel {
        .leftSection {
          width: 55%;
          height: 100%;
          @include Flex();
          img {
            height: 85%;
          }
          #slide3Img {
            width: 80%;
            height: auto;
          }
        }
        .rightSection {
          // width: ;
          gap: 2.2rem;
          .count {
            font-size: 1.5rem;
          }
          .heading {
            font-size: 1.7rem;
          }
          .info {
            font-size: 1.3rem;
            line-height: 2.2rem;
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .container {
      width: 300%;
      height: 100vh;
      .panel {
        .rightSection {
          padding-left: 0;
          gap: 2.2rem;
          .count {
            margin-left: 1rem;
            font-size: 1.5rem;
          }
          .heading {
            margin-left: 1rem;
            font-size: 1.7rem;
          }
          .info {
            width: 80%;
            margin-left: 1rem;
            font-size: 1.2rem;
            line-height: 2rem;
          }
          .howItWorksbtn {
            margin-left: 1rem;
            font-size: 1.2rem;
          }
          .scrollbtn {
            margin-left: 1rem;
            font-size: 1rem;

            img {
              height: 1rem;
            }
          }
        }
      }
    }
  }
  @media (max-width: 700px) {
    .container {
      width: 100vw;
      height: 100%;
      flex-direction: column;
      .panel {
        flex-direction: column;
        .leftSection {
          height: 50vh;
          width: 100vw;
          img {
            height: 95%;
          }
          #slide2Img {
            height: 90%;
            width: auto;
          }
          #slide3Img {
            width: auto;
            height: 90%;
          }
        }

        .rightSection {
          height: 50vh;
          width: 100vw;
          gap: 1rem;
          padding: 0;
          .count {
            display: none;
            font-size: 1rem;
          }
          .heading {
            margin-left: 4rem;
            font-size: 1.2rem;
          }
          .info {
            margin-left: 4rem;
            font-size: 1rem;
            line-height: 1.7rem;
          }
          .howItWorksbtn {
            margin-left: 4rem;
            font-size: 1rem;
          }
          .scrollbtn {
            margin-left: 4rem;
            font-size: 1rem;
            display: none;

            img {
              height: 1rem;
            }
          }
        }
      }
    }
  }
  @media (max-width: 520px) {
    .container {
      .panel {
        .leftSection {
          #slide2Img {
            width: 90%;
            height: auto;
          }
          #slide3Img {
            width: 95%;
            height: auto;
          }
        }
        .rightSection {
          .heading {
            margin-left: 2.5rem;
          }
          .info {
            margin-left: 2.5rem;
          }
          .howItWorksbtn {
            margin-left: 2.5rem;
          }
          .scrollbtn {
            margin-left: 2.5rem;
          }
        }
      }
    }
  }

  #section1 {
    width: 100%;
    height: 100%;
    .leftSection {
      background-color: #ffb0b0;
    }
  }
  #section2 {
    width: 100%;
    height: 100%;
    .leftSection {
      background-color: #c9e4c5;
    }
  }
  #section3 {
    width: 100%;
    height: 100%;
    .leftSection {
      background-color: #ffd6a1;
    }
  }
  #section4 {
    width: 100%;
    height: 100%;
    background-color: blue;
  }
}
