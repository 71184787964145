@import "../styles/mixin.scss";

.footer {
  Link {
    text-decoration: none;
    color: white;
  }
  .footerContainer {
    display: flex;
    padding: 5rem 5%;
    justify-content: space-between;
    color: white;
    background: linear-gradient(97.92deg, #012e2e 16.37%, #082323 100%);

    .footerLeft {
      display: flex;
      flex-direction: column;
      .socialmediaContainer {
        display: flex;
        gap: 2rem;

        img {
          cursor: pointer;
          width: 45px;
          &:hover {
            fill: black;
            transform: scale(1.1);
            transition: ease 0.4s;
          }
        }
      }
      .logo {
        cursor: pointer;
        @include Flex();
        width: 260px;
        padding: 1rem;
        background-color: white;
        border-radius: 8px;

        img {
          width: 250px;
        }
      }
      p {
        font-size: 1.5rem;
        font-weight: 500;
      }
      .downloadApp {
        margin-top: 1.5rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 1rem;
        img {
          width: 200px;
        }
      }
    }
    .footerRight {
      .footerlink {
        gap: 5rem;
        display: flex;
        justify-content: flex-end;
        .link {
          list-style: none;
          font-weight: 400;
          font-size: 1.2rem;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          h3 {
            font-size: 1.7rem;
            font-weight: 600;
          }
          li {
            cursor: pointer;
            &:hover {
              color: #b4b4b4;
            }
          }
        }
      }
    }
  }
  .copyright {
    background-color: #005b5b;
    color: white;
    font-size: 1.3rem;
    text-align: center;
    padding: 1.3rem;
  }
  @media (max-width: 1440px) {
    .footerContainer {
      padding: 5rem 5%rem;
    }
  }
  @media (max-width: 1430px) {
    .footerContainer {
      .footerLeft {
        .socialmediaContainer {
          img {
            width: 40px;
          }
        }
        .logo {
          width: 250px;
          img {
            width: 240px;
          }
        }
        p {
          font-size: 1.4rem;
        }
      }
      .footerRight {
        .footerlink {
          gap: 3rem;
        }
      }
    }
  }
  @media (max-width: 1000px) {
    .footerContainer {
      .footerLeft {
        .socialmediaContainer {
          img {
            width: 35px;
          }
        }
        .logo {
          width: 200px;
          img {
            width: 190px;
          }
        }
        p {
          font-size: 1.2rem;
        }
      }
      .footerRight {
        .footerlink {
          gap: 1.5rem;
          .link {
            font-size: 1rem;
            h3 {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }
  @media (max-width: 831px) {
    .footerContainer {
      padding: 3rem 5%;
      .footerLeft {
        .socialmediaContainer {
          gap: 1rem;
          img {
            width: 30px;
          }
        }
        .logo {
          width: 140px;
          img {
            width: 140px;
          }
        }
        p {
          font-size: 1.2rem;
        }
      }
      .footerRight {
        .footerlink {
          gap: 1rem;
          .link {
            font-size: 1rem;
            h3 {
              font-size: 1.5rem;
            }
          }
        }
        .downloadApp {
          img {
            width: 200px;
          }
        }
      }
    }
    .copyright {
      font-size: 1.1rem;
      padding: 0.7rem;
    }
  }
  // @media (max-width: 630px) {
  //   .footerContainer {
  //     .footerRight {
  //       .downloadApp {
  //         gap: 8px;
  //         img {
  //           width: 170px;
  //         }
  //       }
  //     }
  //   }
  // }
  @media (max-width: 600px) {
    .footerContainer {
      .footerLeft {
        .socialmediaContainer {
          img {
            width: 35px;
          }
        }
        .logo {
          width: 140px;
          img {
            width: 140px;
          }
        }
        p {
          font-size: 1.2rem;
        }
      }
      .footerRight {
        .footerlink {
          .link {
            font-size: 0.9rem;
            gap: 0.8rem;
            h3 {
              font-size: 1.3rem;
            }
          }
        }
      }
    }
  }
  @media (max-width: 550px) {
    .footerContainer {
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      .footerLeft {
        width: 100%;
        @include Flex();
        .socialmediaContainer {
          gap: 2rem;
          img {
            width: 35px;
          }
        }
        .logo {
          width: 110px;
          img {
            width: 110px;
          }
        }
        p {
          font-size: 1.1rem;
        }
        .downloadApp {
          justify-content: center;
          img {
            width: 180px;
          }
        }
      }
      .footerRight {
        .footerlink {
          width: 100%;
          justify-content: center;
          .link {
            font-size: 0.95rem;
            gap: 0.8rem;
            text-align: center;
            h3 {
              font-size: 1.2rem;
              margin: 0;
            }
          }
        }
      }
    }
  }
  @media (max-width: 450px) {
    .footerContainer {
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      .footerLeft {
        width: 100%;
        @include Flex();
        .socialmediaContainer {
          gap: 1.5rem;
          img {
            width: 35px;
          }
        }
        .logo {
          width: 100px;
          img {
            width: 100px;
          }
        }
      }
      .footerRight {
        width: 100%;
        margin-top: 2rem;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;
        .link {
          gap: 0.8rem;
          text-align: center;
          h3 {
            margin: 0;
          }
        }
      }
    }
    .copyright {
      font-size: 1rem;
      padding: 0.7rem;
    }
  }
}
