@import "../styles/mixin.scss";

.slider_container {
  .sliderArrow {
    height: 40px;
  }
  .forwardBtn {
    position: absolute;
    border: none;
    background: transparent;
    align-self: center;
    right: 3%;
    cursor: pointer;
  }
  .backwardBtn {
    position: absolute;
    border: none;
    background: transparent;
    align-self: center;
    left: 3%;
    z-index: 1;
    cursor: pointer;
  }
  .paginationBtn {
    transform: translateY(-100px);
    height: 20;
    width: 20;
    border-radius: 50%;
    border: 0;
  }
  .activePagination {
    height: 16;
    width: 16;
    border-radius: 50%;
    border: 0;
    background: black;
  }

  .panel {
    width: 100vw;
    height: 100vh;
    @include Flex();
    background-color: #248277;
    flex-direction: column;
    color: white;
    gap: 2rem;
    text-align: center;
    h2 {
      width: 80%;
      margin: 0;
      font-size: 2.7rem;
    }
    p {
      margin: 0;
      font-size: 1.2rem;
    }
  }
  @media (max-width: 650px) {
    .panel {
      height: 80vh;
      h2 {
        font-size: 2rem;
      }
      p {
        font-size: 1rem;
      }
    }
    .paginationBtn {
      height: 10;
      width: 10;
    }
    .activePagination {
      height: 10;
      width: 10;
    }
  }
  @media (max-width: 500px) {
    .panel {
      height: 70vh;
      h2 {
        font-size: 1.7rem;
      }
      p {
        font-size: 1rem;
      }
    }
    .paginationBtn {
      height: 10;
      width: 10;
    }
    .activePagination {
      height: 10;
      width: 10;
    }
    .forwardBtn {
      right: 1%;
    }
    .backwardBtn {
      left: 1%;
    }
    .sliderArrow {
      height: 30px;
    }
  }
}
